

import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import ImportData from './export.vue'

@Component(
  {
    name: 'WarehousingAdd',
    components: { UploadFile, ImportData }
  }
)
export default class extends Vue {
  @Ref('form') readonly form!: ElForm

  // 0.1到9999999.9的数字(非必填）
  private unitPriceV = (rule: object, value: any, callback: Function) => {
    if ((value && !Number(value)) || (value && (value > 9999999.9 || value < 0.1))) {
      return callback(new Error('单价为0.1到9999999.9的数字'))
    } else {
      return callback()
    }
  }

  // 1到9999整数
  private operationStockV = (rule: object, value: string, callback: Function) => {
    const reg = /^[1-9][0-9]*$/
    if (!reg.test(value) || Number(value) > 9999999) {
      return callback(new Error('请输入1到9999999整数'))
    } else {
      return callback()
    }
  }

  private formData = {
    projectId: '',
    storageDate: '',
    storageExplain: '',
    imgList: [],
    fileList: [],
    detailedList: []
  } as any

  private isShowImport = false

  private materialTypeData = [] // 物料类型
  private warehouseData = [] // 仓库列表
  private supplierData = [] // 供应商类型

  private loading = false

  private rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'blur' }
    ],
    storageDate: [
      { required: true, message: '请选择入库日期', trigger: 'blur' }
    ],
    detailedList: [
      { required: true, message: '请添加入库物料明细', trigger: 'change' }
    ],
    materialName: [
      { required: true, message: '请输入产品名称', trigger: 'change' }
    ],
    specifications: [
      { required: true, message: '请输入规格型号', trigger: 'change' }
    ],
    materialTypeId: [
      { required: true, message: '请选择物料分类', trigger: 'change' }
    ],
    // supplierId: [
    //   { required: true, message: '请输入供应商名称', trigger: 'change' }
    // ],
    operationStock: [
      { required: true, message: '请输入入库数量', trigger: 'change' },
      { validator: this.operationStockV, trigger: ['blur', 'change'] }
    ],
    warehouseId: [
      { required: true, message: '请选择入库仓库', trigger: 'change' }
    ],
    unitPrice: [
      { validator: this.unitPriceV, trigger: ['blur', 'change'] }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('formData.projectId')
  loadWarehouseData () {
    this.formData.detailedList.forEach((item: any) => {
      item.warehouseId = ''
    })
    this.$axios.get(this.$apis.material.selectYhWarehouseByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.warehouseData = res.list
    })
  }

  created () {
    this.loadMaterialType() // 物料类型
    this.loadSupplierType() // 加载供应商
  }

  // 物料类型
  loadMaterialType () {
    this.$axios.get(this.$apis.material.selectYhMaterialTypeByList, {
      state: '1'
    }).then(res => {
      this.materialTypeData = res || []
    })
  }

  // 加载供应商
  loadSupplierType () {
    this.$axios.get(this.$apis.material.selectYhSupplierByPage).then(res => {
      this.supplierData = res.list
    })
  }

  // 导入数据加入表格中
  onExportResult (data: any) {
    // console.log('添加', data)
    const successList = data.data.successList.map((item: any) => {
      return {
        materialTypeId: item.materialTypeId,
        materialName: item.materialName,
        specifications: item.specifications,
        brand: item.brand,
        company: item.company,
        unitPrice: item.unitPrice,
        supplierId: item.supplierId,
        operationStock: item.stock,
        warehouseId: item.warehouseId
      }
    }) || []
    this.formData.detailedList.push(...successList)
    // console.log('处理后数据', this.info.taskList)
  }

  addMaterial () {
    this.formData.detailedList.push({
      materialTypeId: '', // 分类id
      materialName: '', // 物料名称
      specifications: '', // 规格
      brand: '', // 品牌
      unitPrice: '', // 单价
      company: '', // 单位
      supplierId: '', // 供应商ID
      operationStock: '', // 入库数量
      warehouseId: '' // 仓库ID
    })
  }

  deleteDetailedList (index: number) {
    this.formData.detailedList.splice(index, 1)
  }

  // 图片成功返回
  onSuccess (file: any) {
    (this.formData.imgList as any).push(file)
  }

  imgRemove (index: any) {
    this.formData.imgList.splice(index, 1)
  }

  // 文件上传
  onSuccess2 (file: any) {
    this.formData.fileList = [file]
  }

  onExport () {
    if (!this.formData.projectId) {
      this.$message.warning('请先选择项目')
      return
    }
    this.isShowImport = true
  }

  submit () {
    (this.$refs.form as ElForm).validate((valid: any) => {
      if (valid) {
        // for (let i = 0; i < this.formData.detailedList.length; i++) {
        this.$axios.post(this.$apis.material.insertYhStorage, this.formData).then(() => {
          this.$router.back()
        })
      }
    })
  }
}
